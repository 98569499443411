import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
  useGridApiRef
} from '@mui/x-data-grid';
import {
  Alert,
  Card,
  CardContent,
  CircularProgress,
  Slide,
  Typography
} from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';
import {
  OperationVariables,
  QueryLazyOptions,
  gql,
  useMutation
} from '@apollo/client';

const DELETE_USER_METADATA = gql`
  mutation DeleteUserMetadata($metadataId: String!) {
    DeleteUserMetadata(metadataId: $metadataId)
  }
`;
const UPSERT_USER_METADATA = gql`
  mutation UpsertUserMetadata($input: UpsertUserMetadataInput!) {
    UpsertUserMetadata(input: $input) {
      id
      key
      value
    }
  }
`;

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
  isAdding: boolean;
  setIsAdding: React.Dispatch<React.SetStateAction<boolean>>;
  tempId: string;
  setTempId: React.Dispatch<React.SetStateAction<string>>;
}

interface Metadata {
  id: string;
  key: string;
  value: string | number;
}

function EditToolbar(props: EditToolbarProps) {
  const {
    setRows,
    setRowModesModel,
    isAdding,
    setIsAdding,
    tempId,
    setTempId
  } = props;

  const handleClick = () => {
    if (isAdding) return;
    const newId = randomId();
    setTempId(newId);
    setRows((oldRows) => {
      return [...oldRows, { id: newId, key: '', value: '', isNew: true }];
    });
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newId]: { mode: GridRowModes.Edit, fieldToFocus: 'key' }
    }));
    setIsAdding(true);
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

export default function UserMetadata({
  userId,
  userEmail,
  metadata,
  executeGetUser
}: {
  userId: string;
  userEmail: string;
  metadata: Metadata[];
  executeGetUser: (options?: QueryLazyOptions<OperationVariables>) => void;
}) {
  const initialRows: GridRowsProp = metadata.map((row) => ({
    id: row.id,
    key: row.key,
    value: row.value
  }));

  const apiRef = useGridApiRef();

  const [
    executeDelete,
    { loading: deleteLoading, data: deleteData, error: deleteError }
  ] = useMutation(DELETE_USER_METADATA);
  const [
    executeUpsert,
    { loading: upsertLoading, data: upsertData, error: upsertError }
  ] = useMutation(UPSERT_USER_METADATA);

  const [isUpsertSuccessVisible, setIsUpsertSuccessVisible] = useState(false);
  const [isDeleteSuccessVisible, setIsDeleteSuccessVisible] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [lastUpdatedRowId, setLastUpdatedRowId] = useState(null);
  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [tempId, setTempId] = useState('');
  const [isAdding, setIsAdding] = useState(false);

  const isCellEditable = !deleteLoading && !upsertLoading;

  useEffect(() => {
    if (upsertData?.UpsertUserMetadata) {
      setIsDeleteSuccessVisible(false);
      setIsUpsertSuccessVisible(true);

      const rowsToSet = upsertData.UpsertUserMetadata.map((row) => ({
        id: row.id,
        key: row.key,
        value: row.value
      }));

      setRows((prevRows) => {
        return rowsToSet;
      });
      setIsAdding(false);
    }
  }, [upsertData]);

  useEffect(() => {
    if (deleteData?.DeleteUserMetadata) {
      setIsUpsertSuccessVisible(false);
      setIsDeleteSuccessVisible(true);
    }
  }, [deleteData]);

  const clearMessages = () => {
    setIsDeleteSuccessVisible(false);
    setIsUpsertSuccessVisible(false);
    setValidationError(null);
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
      // Check if the row was a new row and remove it
      if (params.row.isNew) {
        setIsAdding(false);
        setRows(rows.filter((row) => row.id !== params.id));
      }
    }
    // Prevent the default behavior to stop the row from losing focus if needed
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const row = rows.find((row) => row.id === id);
    setRows(rows.filter((row) => row.id !== id));

    executeDelete({
      variables: { metadataId: id }
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setIsAdding(false);
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    // const updatedRow = { ...newRow, isNew: false };
    // setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));
    clearMessages();

    const keyExists = rows.some(
      (row) => row.key === newRow.key && row.id !== newRow.id
    );
    if (keyExists) {
      setValidationError('Key already exists');
      return;
    }
    if (newRow.key === '') {
      setValidationError('Key cannot be empty');
      return;
    }
    if (newRow.value === '') {
      setValidationError('Value cannot be empty');
      return;
    }

    // TODO - Why isn't this working
    setRowModesModel({
      ...rowModesModel,
      [tempId]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    setRows(rows.filter((row) => row.id !== tempId));

    return executeUpsert({
      variables: {
        input: {
          userId,
          newMetadata: {
            key: (newRow.key as string).trim(),
            value: (newRow.value as string).trim()
          }
        }
      }
    }).catch((error) => {
      // Revert the row mode to edit if there is an error
      setRowModesModel((prevModel) => ({
        ...prevModel,
        [newRow.id]: { mode: GridRowModes.Edit }
      }));

      console.error('Error updating metadata:', error);
      throw error; // Propagate errors up to DataGrid for handling
    });
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    const totalRowsInGrid = apiRef.current.getRowsCount();

    if (totalRowsInGrid !== rows.length) {
      apiRef.current.setRows([...rows]);
    }
  }, [rows, rowModesModel]);

  const columns: GridColDef[] = [
    {
      field: 'key',
      headerName: 'Key',
      type: 'string',
      width: 320,
      editable: true
    },
    {
      field: 'value',
      headerName: 'Value',
      type: 'string',
      width: 800,
      align: 'left',
      headerAlign: 'left',
      editable: true
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      align: 'right',
      headerAlign: 'right',
      cellClassName: 'actions',
      getActions: (params) => {
        const { id } = params;
        const isInEditMode =
          rowModesModel[id]?.mode === GridRowModes.Edit && isCellEditable;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />
        ];
      }
    }
  ];

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Typography
          sx={{ mt: 1, mb: 1, ml: 1 }}
          color="textSecondary"
          variant="overline"
        >
          Metadata
        </Typography>

        <Box
          sx={{
            width: '100%',
            '& .actions': {
              color: 'text.secondary'
            },
            '& .textPrimary': {
              color: 'text.primary'
            }
          }}
        >
          <DataGrid
            // sx={{
            //   '& .MuiDataGrid-topContainer': {
            //     backgrounColor: 'red'
            //   }
            // }}
            apiRef={apiRef}
            rows={rows}
            onStateChange={(state) => {
              if (state.rows.totalRowCount !== rows.length) {
                setRows((oldRows) => oldRows);
              }
            }}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
              toolbar: EditToolbar as GridSlots['toolbar']
            }}
            slotProps={{
              toolbar: {
                setRows,
                setRowModesModel,
                isAdding,
                setIsAdding,
                tempId,
                setTempId
              }
            }}
            sortModel={[
              {
                field: 'key',
                sort: 'asc'
              }
            ]}
          />
        </Box>
        {isUpsertSuccessVisible && !upsertLoading && !deleteLoading && (
          <Slide
            in={isUpsertSuccessVisible}
            direction="up"
            mountOnEnter
            unmountOnExit
          >
            <Alert
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
              onClose={clearMessages}
            >
              Metadata successfully updated 😊
            </Alert>
          </Slide>
        )}
        {isDeleteSuccessVisible && !upsertLoading && !deleteLoading && (
          <Slide
            in={isDeleteSuccessVisible}
            direction="up"
            mountOnEnter
            unmountOnExit
          >
            <Alert
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
              onClose={clearMessages}
            >
              Metadata successfully deleted 🗑️
            </Alert>
          </Slide>
        )}
        {(deleteLoading || upsertLoading) && <CircularProgress />}
        {!!validationError && (
          <Slide
            in={!!validationError}
            direction="up"
            mountOnEnter
            unmountOnExit
          >
            <Alert
              icon={<ErrorOutlineIcon fontSize="inherit" />}
              severity="error"
              onClose={clearMessages}
            >
              {validationError}
            </Alert>
          </Slide>
        )}
      </CardContent>
    </Card>
  );
}
