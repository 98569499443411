import { Formik } from 'formik';
import * as Yup from 'yup';
import { Card, CardContent, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { CREATE_COUPON, GET_COUPONS } from '../../../graphql/queries';
import CouponForm from './CouponForm';

const validateDecimal = (val: any) => {
  let pattern = /^\d+(\.\d{0,2})?$/;
  if (val !== undefined) {
    return pattern.test(val);
  }
  return true;
};

const INVALID_DECIMAL = 'Please enter a valid decimal number';

export const validationShape = {
  code: Yup.string().min(1).required('Code is required'),
  description: Yup.string().min(1).required('Description is required'),
  discountDays: Yup.number().when('discountType', {
    is: 'numberOfDays',
    then: Yup.number()
      .required('Discount days is required')
      .integer('Discount days must be an integer')
      .min(1, 'Discount days must be positive')
  }),
  discountAmountCad: Yup.number().when('discountType', {
    is: 'fixedAmount',
    then: Yup.number()
      .required('Discount amount is required')
      .positive('Must be a positive number')
      .test('is-decimal', INVALID_DECIMAL, validateDecimal)
  }),
  discountAmountUsd: Yup.number().when('discountType', {
    is: 'fixedAmount',
    then: Yup.number()
      .required('Discount amount is required')
      .positive('Must be a positive number')
      .test('is-decimal', INVALID_DECIMAL, validateDecimal)
  }),
  discountPercentage: Yup.number().when('discountType', {
    is: 'percentage',
    then: Yup.number()
      .required('Required')
      .integer('Must be an integer')
      .positive('Must be a positive number')
      .max(100, 'Must be 100 or less')
  }),
  maxRedemptions: Yup.number().positive('Max Redemptions must be positive'),
  productIds: Yup.string().required('Product is required')
};

const CreateCoupon = ({ initialValues, setModalOpen }) => {
  const [
    executeCreateCoupon,
    { loading: createLoading, data: createData, error: createError }
  ] = useMutation(CREATE_COUPON);

  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" variant="overline">
          Create a Coupon
        </Typography>
      </CardContent>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape(validationShape)}
        onSubmit={(values, actions) => {
          console.log('productIdss: ', values.productIds.split(','));
          const variables = {
            ...values,

            productIds:
              typeof values.productIds === 'string'
                ? values.productIds.split(',')
                : values.productIds,
            validStartDate: values.validStartDate.toISOString(),
            validEndDate: values.validEndDate.toISOString(),
            maxRedemptions: !values.maxRedemptions ? -1 : values.maxRedemptions
          };

          return executeCreateCoupon({
            variables,
            refetchQueries: [GET_COUPONS]
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          touched,
          values,
          setFieldValue
        }) => {
          return (
            <CouponForm
              values={values}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={createError}
              data={createData}
              loading={createLoading}
              editMode={false}
              formErrors={errors}
              touched={touched}
              setModalOpen={setModalOpen}
            />
          );
        }}
      </Formik>
    </Card>
  );
};

export default CreateCoupon;
