import { FC } from 'react';
import { Box, Divider, Grid, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { getDollars } from '../../../../utils/currency';
import UserInvoices from '../UserInvoices';
import { parseUserInvoices } from '../UserDetails';
import NavItem from 'src/components/NavItem';
import { OrderData } from './UserMemberships';

type Props = {
  expiredMembership: OrderData;
};

const UserExpiredMembership: FC<Props> = ({ expiredMembership }) => {
  return (
    <Box>
      <Typography variant="h6" component="h2" sx={{ py: 2 }}>
        Expired Membership
      </Typography>

      <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          <Typography color="textPrimary" variant="subtitle2">
            Status
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {expiredMembership.status}
          </Typography>
        </Grid>

        <Grid item md={3} xs={12}>
          <Typography color="textPrimary" variant="subtitle2">
            Membership Level
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {expiredMembership.level}
          </Typography>
        </Grid>

        <Grid item md={3} xs={12}>
          <Typography color="textPrimary" variant="subtitle2">
            Date Started
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {format(
              new Date(expiredMembership.startDate.formatted),
              'MMM dd, yyyy'
            )}
          </Typography>
        </Grid>

        <Grid item md={3} xs={12}>
          <Typography color="textPrimary" variant="subtitle2">
            Date Expired / Cancelled
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {format(
              new Date(expiredMembership.expiredSubscription.updated.formatted),
              'MMM dd, yyyy'
            )}
          </Typography>
        </Grid>

        {expiredMembership.subscription && (
          <>
            <Grid item md={3} xs={12}>
              <Typography color="textPrimary" variant="subtitle2">
                Provider
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {expiredMembership.subscription.provider}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography color="textPrimary" variant="subtitle2">
                Provider Status
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {expiredMembership.subscription.status}
              </Typography>
            </Grid>

            <Grid item md={3} xs={12}>
              <Typography color="textPrimary" variant="subtitle2">
                Period
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {expiredMembership.subscription.period}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography color="textPrimary" variant="subtitle2">
                Price
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {`${getDollars(
                  expiredMembership.subscription.price
                )} ${expiredMembership.subscription.currency.toUpperCase()}`}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography color="textPrimary" variant="subtitle2">
                SubscriptionId
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Tooltip
                  title={expiredMembership.subscription.subscriptionId}
                  placement="top-start"
                >
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    style={{
                      overflow: 'hidden',
                      maxWidth: '150px',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {expiredMembership.subscription.subscriptionId}
                  </Typography>
                </Tooltip>
              </Box>
            </Grid>
            {expiredMembership.subscription?.orderId && (
              <Grid item md={3} xs={12}>
                <Typography color="textPrimary" variant="subtitle2">
                  OrderId
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {expiredMembership.subscription.orderId}
                </Typography>
              </Grid>
            )}

            <Grid item md={3} xs={12}>
              <Typography color="textPrimary" variant="subtitle2">
                Coupon Code
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {expiredMembership.subscription.couponCode ?? 'N/A'}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography color="textPrimary" variant="subtitle2">
                Coupon Number of Days
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {expiredMembership.subscription.couponNumberOfDays ?? 'N/A'}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>

      <Divider sx={{ marginTop: '10px' }} />

      <Box sx={{ paddingTop: '10px' }}>
        <NavItem depth={0} title="Show Invoices" collapse={true} color="text">
          <Box sx={{ paddingLeft: '20px' }}>
            <Typography variant="h6" component="h2" sx={{ py: 2 }}>
              Membership Invoices
            </Typography>
            <UserInvoices
              isShown={true}
              invoices={parseUserInvoices([], [expiredMembership])}
              handleClickInvoiceRefund={() => {}}
              refundInvoiceLoading={false}
              userLoading={false}
            />
          </Box>
        </NavItem>
      </Box>
    </Box>
  );
};

export default UserExpiredMembership;
