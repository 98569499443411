import { FC, useState, useEffect } from 'react';
import { useMutation, gql } from '@apollo/client';
import {
  Alert,
  Box,
  Divider,
  Grid,
  Tooltip,
  Typography,
  Button,
  Modal,
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  TextField
} from '@mui/material';
import { format } from 'date-fns';
import { getDollars } from '../../../../utils/currency';
import UserInvoices from '../UserInvoices';
import { parseUserInvoices } from '../UserDetails';
import NavItem from 'src/components/NavItem';
import { User } from 'src/pages/dashboard/UsersDetails';
import dayjs from 'dayjs';
import {
  CANCEL_MEMBERSHIP,
  APPLY_MEMBERSHIP_COUPON
} from 'src/graphql/queries';

export type OrderData = {
  id: string;
  level: string;
  status: string;
  created: {
    formatted: string;
  };
  startDate: {
    formatted: string;
  };
  endDate: {
    formatted: string;
  };
  subscription?: {
    id: string;
    created: {
      formatted: string;
    };
    updated: {
      formatted: string;
    };
    subscriptionId: string;
    status: string;
    period: string;
    provider: string;
    price: number;
    currency: string;
    currentPeriodEnd: {
      formatted: string;
    };
    providerCustomerId: string;
    providerPaymentMethodId: string;
    email: string;
    couponAtSubscriptionCreation?: {
      couponCode: String;
      couponDescription: String;
      couponNumberOfDays: number;
      discountAmount: number;
    };
    couponCode?: string;
    couponNumberOfDays?: number;
    discountAmount?: number;
    cancelAtPeriodEnd: boolean;
    orderId?: string;
  };
  expiredSubscription?: {
    id: string;
    created: {
      formatted: string;
    };
    updated: {
      formatted: string;
    };
    subscriptionId: string;
    status: string;
    period: string;
    provider: string;
    price: number;
    currency: string;
    currentPeriodEnd: {
      formatted: string;
    };
    couponAtSubscriptionCreation?: {
      couponCode: String;
      couponDescription: String;
      couponNumberOfDays: number;
      discountAmount: number;
    };
    providerCustomerId: string;
    providerPaymentMethodId: string;
    email: string;
    cancelAtPeriodEnd: boolean;
    orderId?: string;
  };
};

type Props = {
  loading: boolean;
  user: User;
  membershipData: OrderData;
  expiredMemberships: OrderData[];
  expired?: boolean;
  handleClickInvoiceRefund: (id: string, provider: string) => void;
  refundInvoiceLoading: boolean;
  userLoading: boolean;
  refreshUser: (email: string) => void;
};

const UserMembership: FC<Props> = ({
  user,
  membershipData,
  expiredMemberships,
  loading,
  expired = false,
  handleClickInvoiceRefund,
  refundInvoiceLoading,
  userLoading,
  refreshUser
}) => {
  let cancellationMessage;

  if (expiredMemberships && expiredMemberships.length > 1) {
    expiredMemberships.sort(
      (a: OrderData, b: OrderData) =>
        new Date(a.endDate.formatted).getTime() -
        new Date(b.endDate.formatted).getTime()
    );
  }

  const lastMembership: OrderData =
    membershipData ??
    (expiredMemberships && expiredMemberships.length > 0
      ? expiredMemberships[expiredMemberships.length - 1]
      : null);

  if (
    lastMembership &&
    lastMembership.subscription &&
    lastMembership.subscription.cancelAtPeriodEnd
  ) {
    cancellationMessage = `Subscription cancellation request has been received. Current subscription will remain active until ${format(
      new Date(lastMembership.subscription.currentPeriodEnd.formatted),
      'MMM dd, yyyy'
    )}.`;
  } else if (
    !membershipData &&
    expiredMemberships &&
    expiredMemberships.length > 0
  ) {
    lastMembership.subscription = lastMembership.expiredSubscription;
    cancellationMessage = `Expired on ${format(
      new Date(lastMembership.endDate.formatted),
      'MMM dd, yyyy'
    )}`;
  }

  const [
    executeCancelMembership,
    {
      loading: cancelMembershipLoading,
      data: cancelMembershipData,
      error: cancelMembershipError
    }
  ] = useMutation(CANCEL_MEMBERSHIP);

  const [madeInitialQuery, setMadeInitialQuery] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  //# Cancel membership
  const handleCancelMembershipActionClicked = () => {
    setMadeInitialQuery(false);
    handleOpenModal();
  };
  const handleCancelMembershipConfirm = async (
    subscriptionId: string,
    provider: string,
    metadata: []
  ) => {
    setMadeInitialQuery(true);
    executeCancelMembership({
      variables: {
        subscriptionId: subscriptionId,
        provider: provider,
        metadata: metadata
      }
    });
  };
  const handleCancelMembershipCancel = async () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (madeInitialQuery && cancelMembershipData) {
      setModalOpen(false);
      refreshUser(user.email);
    }
  }, [cancelMembershipData]);

  const cancelMembershipConfirmModal = () => (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          maxHeight: '100%',
          maxWidth: '500px',
          overflow: 'scroll'
        }}
      >
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ mb: 2 }} color="textPrimary" variant="h5">
                Cancel membership
              </Typography>
              <Typography color="textPrimary" variant="subtitle2">
                Are you sure you want to cancel membership for {user.firstName}{' '}
                {user.lastName}? Their subscription will NOT renew. However they
                will still have access to membership features until{' '}
                {dayjs(lastMembership.endDate.formatted).format('YYYY-MM-DD')}.
              </Typography>
              {madeInitialQuery && cancelMembershipError && (
                <Typography color="red" variant="subtitle2" textAlign="center">
                  {cancelMembershipError.message}
                </Typography>
              )}
            </Box>
            <Grid item md={12} xs={12} textAlign="center">
              {cancelMembershipLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    sx={{ m: 1 }}
                    color="secondary"
                    variant="contained"
                    onClick={handleCancelMembershipCancel}
                  >
                    No
                  </Button>
                  <Button
                    sx={{ m: 1 }}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      handleCancelMembershipConfirm(
                        lastMembership.subscription.subscriptionId,
                        lastMembership.subscription.provider,
                        []
                      );
                    }}
                  >
                    Yes
                  </Button>
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );

  const canCancelSubscription = () => {
    return (
      [
        'active',
        'trialing',
        'SUBSCRIPTION_STATE_ACTIVE',
        'SUBSCRIPTION_STATE_IN_GRACE_PERIOD',
        'SUBSCRIPTION_STATE_ON_HOLD',
        'SUBSCRIPTION_STATE_PAUSED'
      ].includes(lastMembership.subscription.status) &&
      !lastMembership.subscription.cancelAtPeriodEnd
    );
  };

  //# COUPON
  const [couponInputValue, setCouponInputValue] = useState('');

  const handleCouponInputChange = (event) => {
    if (applyCouponError) {
      applyCouponError.message = '';
    }
    setCouponInputValue(event.target.value);
  };

  const handleApplyCouponClicked = () => {
    setMadeInitialQuery(true);
    executeApplyCoupon({
      variables: {
        subscriptionId: lastMembership.subscription.subscriptionId,
        provider: lastMembership.subscription.provider,
        code: couponInputValue
      }
    }).then(() => {
      refreshUser(user.email);
    });
  };
  const [
    executeApplyCoupon,
    {
      loading: applyCouponLoading,
      data: applyCouponData,
      error: applyCouponError
    }
  ] = useMutation(APPLY_MEMBERSHIP_COUPON);

  return (
    <>
      {!loading && lastMembership && cancelMembershipConfirmModal()}
      {!loading && lastMembership ? (
        <div>
          <Typography variant="h6" component="h2" sx={{ py: 2 }}>
            {expired ? 'Expired Membership' : 'Membership'}
          </Typography>

          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography color="textPrimary" variant="subtitle2">
                Status
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {lastMembership.status}
              </Typography>
            </Grid>

            <Grid item md={3} xs={12}>
              <Typography color="textPrimary" variant="subtitle2">
                Membership Level
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {lastMembership.level}
              </Typography>
            </Grid>

            <Grid item md={3} xs={12}>
              <Typography color="textPrimary" variant="subtitle2">
                Date Started
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {format(
                  new Date(lastMembership.startDate.formatted),
                  'MMM dd, yyyy'
                )}
              </Typography>
            </Grid>
            {expired && (
              <Grid item md={3} xs={12}>
                <Typography color="textPrimary" variant="subtitle2">
                  Date Expired / Cancelled
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {format(
                    new Date(
                      membershipData.expiredSubscription.updated.formatted
                    ),
                    'MMM dd, yyyy'
                  )}
                </Typography>
              </Grid>
            )}

            {!expired && (
              <Grid item md={3} xs={12}>
                <Typography color="textPrimary" variant="subtitle2">
                  Next Invoice
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {format(
                    new Date(lastMembership.endDate.formatted),
                    'MMM dd, yyyy'
                  )}
                </Typography>
              </Grid>
            )}

            {lastMembership.subscription && (
              <>
                <Grid item md={3} xs={12}>
                  <Typography color="textPrimary" variant="subtitle2">
                    Provider
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {lastMembership.subscription.provider}
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography color="textPrimary" variant="subtitle2">
                    Provider Status
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {lastMembership.subscription.status}
                  </Typography>
                </Grid>

                <Grid item md={3} xs={12}>
                  <Typography color="textPrimary" variant="subtitle2">
                    Period
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {lastMembership.subscription.period}
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography color="textPrimary" variant="subtitle2">
                    Price
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {`${getDollars(
                      lastMembership.subscription.price
                    )} ${lastMembership.subscription.currency.toUpperCase()}`}
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography color="textPrimary" variant="subtitle2">
                    SubscriptionId
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <Tooltip
                      title={lastMembership.subscription.subscriptionId}
                      placement="top-start"
                    >
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        style={{
                          overflow: 'hidden',
                          maxWidth: '150px',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {lastMembership.subscription.subscriptionId}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Grid>
                {lastMembership.subscription?.orderId && (
                  <Grid item md={3} xs={12}>
                    <Typography color="textPrimary" variant="subtitle2">
                      OrderId
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {lastMembership.subscription.orderId}
                    </Typography>
                  </Grid>
                )}

                <Grid item md={3} xs={12}>
                  <Typography color="textPrimary" variant="subtitle2">
                    Active Coupon
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {lastMembership.subscription.couponCode
                      ? `${lastMembership.subscription.couponCode} (${
                          lastMembership.subscription.couponNumberOfDays > 0
                            ? `${lastMembership.subscription.couponNumberOfDays} days`
                            : ''
                        }${
                          lastMembership.subscription.discountAmount !== 0
                            ? `${getDollars(
                                lastMembership.subscription.discountAmount ?? 0
                              )} discount`
                            : ''
                        })`
                      : 'N/A'}
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography color="textPrimary" variant="subtitle2">
                    Created with Coupon
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {lastMembership.subscription.couponAtSubscriptionCreation
                      ? `${
                          lastMembership.subscription
                            .couponAtSubscriptionCreation.couponCode
                        } (${
                          lastMembership.subscription
                            .couponAtSubscriptionCreation.couponNumberOfDays > 0
                            ? `${lastMembership.subscription.couponAtSubscriptionCreation.couponNumberOfDays} days`
                            : ''
                        }${
                          lastMembership.subscription
                            .couponAtSubscriptionCreation.discountAmount !== 0
                            ? `${getDollars(
                                lastMembership.subscription
                                  .couponAtSubscriptionCreation
                                  .discountAmount ?? 0
                              )} discount`
                            : ''
                        })`
                      : 'N/A'}
                  </Typography>
                </Grid>

                {cancellationMessage ? (
                  <Grid item md={12} xs={12}>
                    <Alert sx={{ mb: 1 }} severity="info">
                      {cancellationMessage}
                    </Alert>
                  </Grid>
                ) : (
                  !expired && (
                    <Grid item md={6} xs={12}>
                      <Typography color="textPrimary" variant="subtitle2">
                        Next Charge
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {`${getDollars(
                          lastMembership.subscription.price +
                            (lastMembership.subscription.discountAmount ?? 0)
                        )} ${lastMembership.subscription.currency.toUpperCase()}`}
                        {' @ ' +
                          format(
                            new Date(
                              lastMembership.subscription.currentPeriodEnd.formatted
                            ),
                            'MMM dd, yyyy'
                          )}
                      </Typography>
                    </Grid>
                  )
                )}
              </>
            )}
          </Grid>
          <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
          {lastMembership.status === 'active' && (
            <div>
              <Box display="flex" alignItems="center" justifyContent="left">
                <Typography color="textSecondary" variant="body2" my={1}>
                  Stop automatic renewal of membership?
                </Typography>
                <Button
                  sx={{ marginLeft: 2 }}
                  color="primary"
                  variant="contained"
                  onClick={handleCancelMembershipActionClicked}
                  disabled={
                    !canCancelSubscription() ||
                    !lastMembership.subscription.provider ||
                    lastMembership.subscription.provider === 'apple'
                  }
                >
                  Cancel Membership
                </Button>
                {!lastMembership.subscription.provider ||
                  (lastMembership.subscription.provider === 'apple' && (
                    <>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        my={1}
                        sx={{ marginLeft: 2 }}
                      >
                        Unavailable for Apple memberships
                      </Typography>
                    </>
                  ))}
              </Box>

              <Box display="flex" alignItems="center" justifyContent="left">
                <Typography color="textSecondary" variant="body2" my={1}>
                  Redeem a coupon for the next billing cycle?
                </Typography>
                <TextField
                  error={Boolean(applyCouponError?.message)}
                  helperText={applyCouponError?.message}
                  label="Coupon"
                  margin="normal"
                  name="coupon"
                  type="text"
                  value={couponInputValue}
                  onChange={handleCouponInputChange}
                  variant="outlined"
                  sx={{ mr: 2, minWidth: 300, marginLeft: 2 }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleApplyCouponClicked}
                  disabled={
                    !(
                      couponInputValue.length > 0 &&
                      lastMembership.subscription.provider === 'stripe'
                    )
                  }
                >
                  {applyCouponLoading &&
                  (applyCouponError?.message.length ?? 0 < 1) ? (
                    <CircularProgress color="success" size={40} />
                  ) : (
                    <Typography>Apply</Typography>
                  )}
                </Button>
                {!lastMembership.subscription.provider ||
                  (lastMembership.subscription.provider !== 'stripe' && (
                    <>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        my={1}
                        sx={{ marginLeft: 2 }}
                      >
                        Only available for Stripe memberships
                      </Typography>
                    </>
                  ))}
              </Box>

              <Divider sx={{ marginTop: '10px' }} />
            </div>
          )}

          <Box sx={{ paddingTop: '10px' }}>
            <NavItem
              depth={0}
              title="Show Invoices"
              collapse={true}
              color="text"
            >
              <Box sx={{ paddingLeft: '0px' }}>
                <Typography variant="h6" component="h2" sx={{ py: 2 }}>
                  Membership Invoices
                </Typography>
                <UserInvoices
                  isShown={true}
                  invoices={parseUserInvoices(lastMembership, [])}
                  handleClickInvoiceRefund={handleClickInvoiceRefund}
                  refundInvoiceLoading={refundInvoiceLoading}
                  userLoading={userLoading}
                />
              </Box>
            </NavItem>
          </Box>
        </div>
      ) : (
        <Alert sx={{ mt: 2 }} severity="info">
          This user does not have a membership!
        </Alert>
      )}
    </>
  );
};

export default UserMembership;
