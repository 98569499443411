import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Box,
  FormHelperText,
  styled
} from '@mui/material';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_COUPONS, GET_STRIPE_PRODUCTS } from '../../../graphql/queries';
import { useEffect, useReducer, useState } from 'react';
import { DiscountType } from '../../../pages/dashboard/Coupons';
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridMoreVertIcon,
  GridToolbar
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { getDollars } from 'src/utils/currency';
import { format, parse } from 'date-fns';
import CouponListForm from './CouponListForm';
import CopyToClipboardButton from 'src/components/shared/CopyToClipboardButton';

// TODO - to discuss
// The DataGrid can only filter the rows according to one criterion at the time.
// To use multi-filters, you need to upgrade to the Pro plan or above.

interface CouponData {
  id: string;
  created: {
    formatted: string;
  };
  updated: {
    formatted: string;
  };
  validStartDate: {
    formatted: string;
  };
  validEndDate: {
    formatted: string;
  };
  code: string;
  description: string;
  discountType: DiscountType;
  discountDays?: number;
  discountAmountCad?: number;
  discountAmountUsd?: number;
  discountPercentage?: number;
  maxRedemptions?: number;
  redemptionsToDate: number;
  disabled: boolean;
  productIds?: string[];
  affiliateId: string;
}

const pageSize = 15;

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '.MuiDataGrid-row.warn': {
    backgroundColor: theme.palette.warning.main
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    whiteSpace: 'break-spaces',
    lineHeight: 1.2
  },
  '& .MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer':
    {
      pl: 1
    }
}));

const parseDiscountType = (discountType: DiscountType) => {
  switch (discountType) {
    case 'fixedAmount':
      return '$';
    case 'percentage':
      return '%';
    case 'numberOfDays':
      return 'Days';
    default:
      return '';
  }
};

const parseMaxRedemptions = (maxRedemptions: number) => {
  if (maxRedemptions === -1) {
    return 'unlimited';
  } else {
    return maxRedemptions;
  }
};

const CouponListDataGrid = ({
  handleOpenEdit,
  setCurrentCoupon,
  setIsEditMode
}) => {
  const [getCoupons, { loading, error, data }] = useLazyQuery(GET_COUPONS);
  const [page, setPage] = useState<number>(0);
  const [pendingPage, setPendingPage] = useState<number>(0);
  const [numFetchedPages, setNumFetchedPages] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  const initalPageMap = new Map<number, CouponData[]>();
  const [pageMap, setPageMap] =
    useState<Map<number, CouponData[]>>(initalPageMap);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [localCoupon, setLocalCoupon] = useState<null | CouponData>(null);

  const {
    loading: stripeProductsLoading,
    error: stripeProductsError,
    data: stripeProductsData
  } = useQuery(GET_STRIPE_PRODUCTS);

  useEffect(() => {
    if (data) {
      mergeCouponData(data?.Coupon ?? []);
    }
  }, [data]);

  const mergeCouponData = (coupons: CouponData[]) => {
    pageMap[pendingPage] = coupons;

    setPage(pendingPage);
    setPageMap(pageMap);
    setHasNextPage(coupons.length >= pageSize);
    setNumFetchedPages(numFetchedPages + 1);
  };

  const DEFAULT_INPUT_VALUES = {
    code: '',
    description: '',
    affiliateId: '',
    productIds: '',
    fulfilmentStatus: '',
    warehouse: '',
    source: 'ADMIN',
    orderBy: 'newestFirst'
  };

  const [inputValues, setInputValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    DEFAULT_INPUT_VALUES
  );

  const queryCoupons = (p: number, isPageChange = false) => {
    console.log('queryCoupons', p);
    if (!isPageChange && p === 0) {
      setPageMap(initalPageMap);
    } else if (pageMap[p]) {
      setPage(p);
      setHasNextPage(p < numFetchedPages - 1);

      return;
    }
    if (!hasNextPage && p > page) {
      return;
    }

    setPendingPage(p);

    getCoupons({
      variables: {
        ...inputValues,
        skip: p * pageSize,
        take: pageSize,
        sort: inputValues.orderBy
      }
    });
  };

  const getCouponStatus = (coupon) => {
    if (coupon.disabled) {
      return 'disabled';
    }
    const startDate = new Date(coupon.validStartDate.formatted);
    const endDate = new Date(coupon.validEndDate.formatted);
    const now = new Date();
    if (startDate.getTime() > now.getTime()) {
      return 'not started';
    }
    if (endDate.getTime() < now.getTime()) {
      return 'expired';
    }
    if (
      coupon.maxRedemptions !== -1 &&
      coupon.maxRedemptions <= coupon.redemptionsToDate
    ) {
      return 'used';
    }
    return 'active';
  };

  const handleClickIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    const menuElement = event.currentTarget;
    const currentCouponElement = menuElement.parentElement.parentElement;
    setAnchorEl(menuElement);
    const coupon: CouponData = pageMap[page].find(
      (c) => c.id === currentCouponElement.getAttribute('data-id')
    );
    setLocalCoupon({
      ...coupon
    });
    setCurrentCoupon({
      ...coupon,
      validStartDate: dayjs(coupon.validStartDate.formatted),
      validEndDate: dayjs(coupon.validEndDate.formatted)
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickEdit = (e) => {
    setIsEditMode(true);
    handleOpenEdit();
    setAnchorEl(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'validStartDate',
      headerName: 'Valid',
      minWidth: 100,
      valueFormatter: (value, row, column, apiRef) => {
        return getCouponStatus(row);
      }
    },

    { field: 'code', headerName: 'Code', minWidth: 120 },
    { field: 'description', headerName: 'Description', minWidth: 200 },
    {
      field: 'discountType',
      headerName: 'Discount',
      minWidth: 280,
      valueGetter: (value, row, column, apiRef) => {
        switch (row?.discountType) {
          case 'fixedAmount':
            return `${getDollars(
              row?.discountAmountUsd as number
            )} (USD)  |  ${getDollars(row?.discountAmountCad as number)} (CAD)`;
          case 'percentage':
            return `${row?.discountPercentage}%`;
          case 'numberOfDays':
            return `${row?.discountDays} Days`;
          default:
            return '';
        }
      }
    },

    {
      field: 'maxRedemptions',
      headerName: 'Redemptions',
      minWidth: 120,
      valueGetter: (value, row, column, apiRef) =>
        row?.redemptionsToDate +
        (row?.maxRedemptions > -1 ? ` of ${row?.maxRedemptions}` : ``)
    },

    {
      field: 'productIds',
      headerName: 'Product',
      minWidth: 280,
      valueGetter: (value, row, colum) => {
        return getProductName(row);
      }
    },
    { field: 'affiliateId', headerName: 'Affiliate ID', minWidth: 150 },
    {
      field: 'link',
      headerName: 'Link',
      minWidth: 30,
      valueGetter: (value, row, colum) => {
        if (getCouponStatus(row) !== 'active') {
          return '';
        }
        let uri = process.env.REACT_APP_SENSAI_SERVER_BASE_URL;
        if (uri.includes('sandbox')) {
          uri = 'https://webpreview.sens.ai/store/checkout/';
        } else if (uri.includes('dev')) {
          uri = 'https://webdev.sens.ai/store/checkout/';
        } else {
          uri = 'https://sens.ai/store/checkout/';
        }

        const p = stripeProductsData?.GetStripeProducts.find((p) =>
          row.productIds.includes(p.metadata.bladeSKU)
        );

        return p
          ? `${uri}?pid=${p.id}&cid=${row.code}`
          : `${uri}?cid=${row.code}`;
      },
      renderCell: (params) => {
        if (!params.value) {
          return <></>;
        }
        return (
          <CopyToClipboardButton textToCopy={params.value} buttonTitle="Copy" />
        );
      }
    },
    {
      field: 'Actions',
      width: 70,
      headerName: '',
      renderCell: () => {
        return (
          <>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-haspopup="true"
              onClick={handleClickIcon}
            >
              <GridMoreVertIcon />
            </IconButton>
            <Menu
              id="coupon-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={handleClose}
            >
              <MenuItem key="edit-coupon" onClick={handleClickEdit}>
                Edit
              </MenuItem>
            </Menu>
          </>
        );
      }
    }
  ];

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: []
  });

  const getProductName = (coupon: CouponData) => {
    if (
      coupon.productIds?.length === 2 &&
      coupon.productIds?.includes('MEMBERSHIP_YEARLY') &&
      coupon.productIds?.includes('MEMBERSHIP_MONTHLY')
    ) {
      return 'Subscription (Yearly or Monthly)';
    }
    const res = coupon.productIds?.map((pid) => {
      if (['SEN_HEADSET_V1.5', 'SEN_HEADSET_V1'].includes(pid)) {
        return 'Sens.ai Headset';
      }
      const stripeProduct = stripeProductsData?.GetStripeProducts.find(
        (p) => p.id === pid || p.metadata.bladeSKU === pid
      );
      return stripeProduct?.name ?? pid;
    });
    return [...new Set(res)].toString();
  };

  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" variant="overline">
          Coupon List
        </Typography>
        <CouponListForm
          queryCoupons={queryCoupons}
          inputValues={inputValues}
          setInputValues={setInputValues}
          stripeProducts={stripeProductsData?.GetStripeProducts}
        />
        <Card>
          <Box sx={{ width: '100%' }}>
            <StyledDataGrid
              slots={{ toolbar: GridToolbar }}
              slotProps={{ toolbar: { showQuickFilter: true } }}
              scrollbarSize={10}
              autoHeight
              rows={pageMap[page] ?? []}
              loading={loading}
              columns={columns}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) =>
                setFilterModel(newFilterModel)
              }
              paginationModel={{
                pageSize: pageSize,
                page: page
              }}
              pagination={true}
              rowCount={
                hasNextPage
                  ? -1
                  : page * pageSize + (pageMap[page] ?? []).length
              }
              paginationMode="server"
              pageSizeOptions={[pageSize]}
              onPaginationModelChange={(x: any) => {
                queryCoupons(x.page, true);
              }}
            />
          </Box>
        </Card>

        {error && (
          <Box sx={{ mt: 3 }}>
            <FormHelperText error>{error.message}</FormHelperText>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CouponListDataGrid;
