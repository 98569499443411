import { useEffect } from 'react';
import React from 'react';
import { Grid, Button, TextField, MenuItem, Box } from '@mui/material';

const CouponListForm = ({
  queryCoupons,
  inputValues,
  setInputValues,
  stripeProducts
}) => {
  useEffect(() => {
    queryCoupons(0);
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInputValues({ [name]: name === 'email' ? value.toLowerCase() : value });
  };

  const searchCoupons = () => {
    // if (
    //   !inputValues.confirmationCode &&
    //   !inputValues.email &&
    //   !(inputValues.dateRangeStart || inputValues.dateRangeEnd)
    // ) {
    //   setIsFormValid(false);
    // } else {
    // setIsFormValid(true);
    queryCoupons(0);
    // }
  };

  return (
    <Box paddingBottom={4}>
      <Grid spacing={2} container>
        <Grid item md={2} xs={2}>
          <TextField
            select
            id="source"
            name="source"
            fullWidth
            label="Source"
            variant="outlined"
            onChange={handleSearchChange}
            value={inputValues.source}
            sx={{ mt: 1 }}
          >
            {['ADMIN', 'AFFILIATE', 'FREE_MODE'].map((s) => (
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={2} xs={2}>
          <TextField
            select
            id="productIds"
            name="productIds"
            fullWidth
            label="SKU"
            variant="outlined"
            onChange={handleSearchChange}
            value={inputValues.productIds}
            sx={{ mt: 1 }}
          >
            <MenuItem value={''}>Any</MenuItem>
            <MenuItem value={'SEN_HEADSET_V1.5'}>
              Sens.ai Headset (V1.5)
            </MenuItem>
            {(stripeProducts ?? []).map((s) => (
              <MenuItem key={s.metadata.bladeSKU} value={s.metadata.bladeSKU}>
                {s.metadata.bladeSKU.startsWith('SEN_HEADSET_V')
                  ? `${s.name} (${s.metadata.bladeSKU.split('_')[2]})`
                  : s.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item md={2} xs={2}>
          <TextField
            label="Code"
            fullWidth
            id="code"
            name="code"
            variant="outlined"
            onChange={handleSearchChange}
            value={inputValues.code}
            // error={!isFormValid}
            sx={{ mt: 1 }}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <TextField
            label="Description"
            fullWidth
            id="description"
            name="description"
            variant="outlined"
            onChange={handleSearchChange}
            value={inputValues.description}
            // error={!isFormValid}
            sx={{ mt: 1 }}
          />
        </Grid>

        <Grid item md={2} xs={2}>
          <TextField
            label="Affiliate ID"
            fullWidth
            id="affiliateId"
            name="affiliateId"
            variant="outlined"
            onChange={handleSearchChange}
            value={inputValues.affiliateId}
            sx={{ mt: 1 }}
          />
        </Grid>

        <Grid item md={1} xs={1} sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={searchCoupons}>
            Find
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CouponListForm;
