import { gql } from '@apollo/client';

export const REFUND_INVOICE = gql`
  mutation SubscriptionRefundInvoice(
    $providerInvoiceId: String!
    $provider: SubscriptionProviderType!
    $revoke: Boolean
    $refundReason: RefundReason!
    $refundNotes: String!
  ) {
    SubscriptionRefundInvoice(
      input: {
        providerInvoiceId: $providerInvoiceId
        provider: $provider
        revoke: $revoke
        refundReason: $refundReason
        refundNotes: $refundNotes
      }
    )
  }
`;

export const UNLOCK_ACCOUNT = gql`
  mutation UnlockAccount($email: String!) {
    UnlockAccount(email: $email)
  }
`;

export const RESET_GUEST_SESSIONS = gql`
  mutation ResetGuestSessions($email: String!) {
    ResetGuestSessions(email: $email)
  }
`;

export const CREATE_COUPON = gql`
  mutation CreateCoupon(
    $validStartDate: String!
    $validEndDate: String!
    $code: String!
    $description: String!
    $discountType: DiscountType!
    $discountDays: Int
    $discountAmountCad: Float
    $discountAmountUsd: Float
    $discountPercentage: Int
    $maxRedemptions: Int
    $disabled: Boolean
    $productIds: [String]
    $affiliateId: String
  ) {
    CreateCoupon(
      input: {
        validStartDate: $validStartDate
        validEndDate: $validEndDate
        code: $code
        description: $description
        discountType: $discountType
        discountDays: $discountDays
        discountAmountCad: $discountAmountCad
        discountAmountUsd: $discountAmountUsd
        discountPercentage: $discountPercentage
        maxRedemptions: $maxRedemptions
        disabled: $disabled
        productIds: $productIds
        affiliateId: $affiliateId
      }
    )
  }
`;
export const UPDATE_COUPON = gql`
  mutation UpdateCoupon(
    $id: ID!
    $validStartDate: String!
    $validEndDate: String!
    $description: String!
    $discountDays: Int
    $discountAmountCad: Float
    $discountAmountUsd: Float
    $discountPercentage: Int
    $maxRedemptions: Int
    $disabled: Boolean
    $productIds: [String]
    $affiliateId: String
  ) {
    UpdateCoupon(
      input: {
        id: $id
        validStartDate: $validStartDate
        validEndDate: $validEndDate
        description: $description
        discountDays: $discountDays
        discountAmountCad: $discountAmountCad
        discountAmountUsd: $discountAmountUsd
        discountPercentage: $discountPercentage
        maxRedemptions: $maxRedemptions
        disabled: $disabled
        productIds: $productIds
        affiliateId: $affiliateId
      }
    )
  }
`;

export const GET_COUPONS = gql`
  query Coupon(
    $code: String
    $description: String
    $source: CouponSource!
    $productIds: String
    $take: Int
    $skip: Int
    $sort: SortType
    $affiliateId: String
  ) {
    Coupon(
      input: {
        code: $code
        description: $description
        source: $source
        productIds: $productIds
        affiliateId: $affiliateId
        take: $take
        skip: $skip
        sort: $sort
      }
    ) {
      id
      created {
        formatted
      }
      updated {
        formatted
      }
      validStartDate {
        formatted
      }
      validEndDate {
        formatted
      }
      code
      description
      discountType
      discountDays
      discountAmountCad
      discountAmountUsd
      discountPercentage
      maxRedemptions
      disabled
      productIds
      redemptionsToDate
      affiliateId
    }
  }
`;

export const GET_ORDERS = gql`
  query GetOrders(
    $confirmationCode: String
    $email: String
    $firstName: String
    $lastName: String
    $warehouse: String
    $fulfilmentStatus: FulfilmentStatus
    $status: PaymentIntentStatus
    $paymentProvider: PaymentProviderType
    $take: Int
    $skip: Int
    $sort: SortType
    $dateRangeStart: String
    $dateRangeEnd: String
    $affiliateId: String
    $country: String
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
    $utmTerm: String
    $utmContent: String
    $dateRangeType: DateRangeType
  ) {
    GetOrders(
      input: {
        confirmationCode: $confirmationCode
        email: $email
        firstName: $firstName
        lastName: $lastName
        warehouse: $warehouse
        fulfilmentStatus: $fulfilmentStatus
        status: $status
        paymentProvider: $paymentProvider
        take: $take
        skip: $skip
        sort: $sort
        dateRangeStart: $dateRangeStart
        dateRangeEnd: $dateRangeEnd
        affiliateId: $affiliateId
        country: $country
        utmSource: $utmSource
        utmMedium: $utmMedium
        utmCampaign: $utmCampaign
        utmTerm: $utmTerm
        utmContent: $utmContent
        dateRangeType: $dateRangeType
      }
    ) {
      id
      created {
        formatted
      }
      updated {
        formatted
      }
      shippedDate {
        formatted
      }
      refundDate {
        formatted
      }
      addressAgeMinutes
      orderToRefundMinutes
      confirmationCode
      paymentProvider
      paymentIntentId
      affiliateId
      fulfilmentId
      fulfilmentAltId
      fulfilmentStatus
      fulfilmentWarehouseCode
      status
      email
      user {
        id
        firstName
        lastName
        email
      }
      amount
      refundAmount
      currency
      lineItems {
        id
        description
        currency
        quantity
        unitAmount
        refundAmount
        productId
        lineNumber
        lineType
        sku
        refundQuantity
        returnQuantity
      }
      shippingAddress {
        country
        city
        postal
        street
        unit
        firstName
        lastName
        phone
        province
        created {
          formatted
        }
        updated {
          formatted
        }
      }
      billingAddress {
        country
        city
        postal
        street
        unit
        firstName
        lastName
        phone
        province
        created {
          formatted
        }
        updated {
          formatted
        }
      }
      tracking {
        id
        courierName
        serviceName
        trackingNumber
        trackingUrl
      }
      country
      utmSource
      utmMedium
      utmCampaign
      utmTerm
      utmContent
      refundReason
      refundNotes
      couponCode
    }
  }
`;

export const GET_ORDERS_COUNT = gql`
  query GetOrdersCount(
    $confirmationCode: String
    $email: String
    $firstName: String
    $lastName: String
    $warehouse: String
    $fulfilmentStatus: FulfilmentStatus
    $status: PaymentIntentStatus
    $paymentProvider: PaymentProviderType
    $dateRangeStart: String
    $dateRangeEnd: String
    $affiliateId: String
    $dateRangeType: DateRangeType
    $country: String
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
    $utmTerm: String
    $utmContent: String
  ) {
    GetOrdersCount(
      input: {
        confirmationCode: $confirmationCode
        email: $email
        firstName: $firstName
        lastName: $lastName
        warehouse: $warehouse
        fulfilmentStatus: $fulfilmentStatus
        status: $status
        paymentProvider: $paymentProvider
        dateRangeStart: $dateRangeStart
        dateRangeEnd: $dateRangeEnd
        affiliateId: $affiliateId
        dateRangeType: $dateRangeType
        country: $country
        utmSource: $utmSource
        utmMedium: $utmMedium
        utmCampaign: $utmCampaign
        utmTerm: $utmTerm
        utmContent: $utmContent
      }
    )
  }
`;

export const GET_STRIPE_PRODUCTS = gql`
  query GetStripeProducts {
    GetStripeProducts {
      id
      name
      metadata {
        shippingRequired
        bladeSKU
      }
    }
  }
`;

export const CANCEL_MEMBERSHIP = gql`
  mutation AdminCancelUserSubscription(
    $subscriptionId: String!
    $provider: SubscriptionProviderType!
    $metadata: [KeyvalInput]
  ) {
    AdminCancelUserSubscription(
      input: {
        subscriptionId: $subscriptionId
        provider: $provider
        metadata: $metadata
      }
    ) {
      id
      status
      cancelAtPeriodEnd
    }
  }
`;

export const APPLY_MEMBERSHIP_COUPON = gql`
  mutation AdminAttachCouponToSubscription(
    $subscriptionId: String!
    $provider: SubscriptionProviderType!
    $code: String!
  ) {
    AdminAttachCouponToSubscription(
      input: {
        subscriptionId: $subscriptionId
        provider: $provider
        code: $code
      }
    ) {
      id
    }
  }
`;
